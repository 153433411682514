<template>
  <div class="outBox">
    <router-view></router-view>
    <!-- 申请试用 -->
    <Try></Try>
  </div>
</template>
<script>
import Try from "@/components/Try/index"; //申请试用
export default {
  components: {
    Try,
  },
};
</script>
<style lang="scss" scoped>
.outBox {
  width: 100%;
}
/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>